<template>
  <page title="My Profile">
    <div class="user">
      <div class="avatar">
        <img v-if="avatar" :src="avatar" />
      </div>
      <span>{{ firstName }}</span>
      <span>{{ lastName }}</span>
      <button @click="logout">
        <icon icon="Icons/leave" />
        <span>Sign Out</span>
      </button>
    </div>
  </page>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import deleteToken from '@/utils/deleteToken'

export default {
  name: 'User',
  components: {},
  props: {},
  data() {
    return {}
  },
  computed: {
    ...mapState('user', ['avatar', 'email', 'firstName', 'lastName', 'isLoggedIn']),
  },
  methods: {
    ...mapActions('user', ['setToken']),
    logout: function () {
      deleteToken()
      window.location.reload()
    },
  },
}
</script>

<style lang="sass" scoped>
.user
  display: flex
  flex-direction: column
  align-items: center

  button
    cursor: pointer
    display: flex
    justify-content: center
    align-items: center
</style>
