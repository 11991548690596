import axios from 'axios'

import deleteToken from '@/utils/deleteToken'

const instance = axios.create({
  baseURL: process.env.VUE_APP_BACKEND_URL,
  withCredentials: true,
})

instance.interceptors.response.use(
  response => {
    return response
  },
  async error => {
    if (error.response.status === 404) {
      return Promise.resolve({ data: { notFound: true } })
    }

    if (error.response.status === 401) {
      deleteToken()
      window.location = '/login'
      return
    }

    return Promise.resolve({ data: { error: true } })
  },
)

export default instance
