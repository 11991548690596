import axios from '@/utils/axios'

import moment from 'moment'

export default {
  namespaced: true,
  state: () => ({
    firstName: '',
    lastName: '',
    email: '',
    avatar: null,
    settings: {
      dateRange: {
        start: moment().subtract(6, 'days').toDate(),
        end: new Date(),
      },
    },
  }),
  mutations: {
    setUserInfo: (state, token) => {
      state.firstName = token.firstName
      state.lastName = token.lastName
      state.email = token.email
      state.avatar = token.avatar
      state.permissions = token.permissions
      state.isLoggedIn = !!token.email?.length
    },
    setSettings: (state, settings) => {
      state.settings = { ...state.settings, ...settings }
    },
  },
  actions: {
    setToken: (context, token) => {
      context.commit('setUserInfo', token)
    },
    setSettings(context, settings) {
      context.commit('setSettings', settings)
    },
    setPersonalQuota(context, { userId, quotaId, value }) {
      axios.post(`/support/users/${userId}/overrideQuota`, { quotaId, value })
    },
    resetPersonalQuota(context, { userId, quotaId }) {
      axios.post(`/support/users/${userId}/resetQuota`, { quotaId })
    },
  },
  getters: {},
}
