<template>
  <div class="app">
    <Menu />
    <transition name="slide-fade">
      <keep-alive include="SupportUsers,SupportProjects">
        <router-view />
      </keep-alive>
    </transition>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

import Menu from '@/components/Menu'

import decodeToken from '@/utils/decodeToken'

export default {
  name: 'App',
  components: {
    Menu,
  },
  methods: {
    ...mapActions('user', ['setToken']),
  },
  created() {
    const token = decodeToken()
    if (token) {
      this.setToken(token)
    }
  },
}
</script>

<style lang="sass" scoped>
.app
  position: relative

.slide-fade-enter-active
  transition: all .3s ease-out
  opacity: 0
  transform: translateX(20px)

.slide-fade-enter-to
  transition-delay: .18s
  opacity: 1
  transform: translateX(0px)

.slide-fade-leave-active
  position: absolute !important
  transition: all .15s cubic-bezier(1, 0.5, 0.8, 1)
  opacity: 1
  transform: translateX(0px)

.slide-fade-leave-to
  opacity: 0
  transform: translateX(20px)
</style>
