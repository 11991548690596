export default () => {
  let domain
  switch (process.env.VUE_APP_NODE_ENV) {
    case 'local':
      domain = 'localhost'
      break
    case 'dev':
      domain = '.dev.flowmapp.com'
      break
    case 'staging':
      domain = '.staging.flowmapp.com'
      break
    case 'production':
      domain = '.flowmapp.com'
      break
  }

  document.cookie = `token=;domain=${domain};`
  document.cookie = `supportToken=;domain=${domain};`
}
