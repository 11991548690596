<template>
  <div class="login">
    <button @click="signIn">
      <img src="https://developers.google.com/identity/images/btn_google_signin_dark_normal_web.png" />
    </button>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import api from '@/utils/api'
import decodeToken from '@/utils/decodeToken'

export default {
  name: 'Login',
  computed: {
    ...mapState('user', ['isLoggedIn']),
  },
  created() {
    if (this.isLoggedIn) {
      return this.$router.replace('/')
    }
  },
  mounted() {
    window.google.accounts.id.initialize({
      client_id: process.env.VUE_APP_GOOGLE_CLIENT_ID,
      scope: 'https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email',
      use_fedcm_for_prompt: true,
      callback: this.onSignInSuccess,
    })
    window.google.accounts.id.prompt()
  },
  methods: {
    ...mapActions('user', ['setToken']),
    signIn() {
      window.google.accounts.id.prompt()
    },
    async onSignInSuccess({ credential: idToken }) {
      await api.login(idToken)
      const token = decodeToken()
      this.setToken(token)
      this.$router.back()
    },
  },
}
</script>

<style lang="sass" scoped>
.login
  display: flex
  justify-content: center
  align-items: center
  height: 100vh
  width: 100vw

  button
    cursor: pointer
    padding: 0
    border: none
    outline: none
    background: none
</style>
