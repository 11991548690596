<template>
  <page :title="pageTitle">
    <h3>Menu is on the left</h3>
  </page>
</template>

<script>
import { mapState } from 'vuex'

import Page from '@/components/Page'

export default {
  name: 'Home',
  components: {
    Page
  },
  computed: {
    ...mapState('user', ['firstName']),
    pageTitle() {
      return `Hello, ${this.firstName}!`
    }
  },
}
</script>
