import Vue from 'vue'

import store from '@/store'

Vue.directive('role', {
  bind: function (el, { value: permission, arg }) {
    const { permissions } = store.state.user
    const isInverted = arg === 'not'
    const isEnabled = Boolean(isInverted ^ Boolean(permissions.includes(permission)))
    if (isEnabled) {
      return
    }

    Vue.nextTick(() => {
      el.remove()
    })
  },
})
