<template>
  <div class="page">
    <div class="page-wrapper">
      <div class="header">
        <h1>{{ title }}</h1>
        <div class="actions">
          <slot name="actions" />
        </div>
      </div>
      <div class="content">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Page',
  props: {
    title: String,
  },
}
</script>

<style lang="sass" scoped>
.page
  position: relative
  width: 100vw

  .page-wrapper
    padding: 24px
    padding-left: calc(144px + 32px)

  .header
    display: flex
    align-items: center
    margin-bottom: 16px

    h1
      font-size: 20px
      line-height: 22px
      font-weight: 700
      margin: 0
      margin-right: 12px

  .content
    width: 100%
</style>
