import Median from './Median'
import Tariffs from './Tariffs'

export default {
  namespaced: true,
  modules: {
    median: Median,
    tariffs: Tariffs,
  }
}
