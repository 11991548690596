import axios from '@/utils/axios'

import Active from './Active'
import Tariffs from './Tariffs'

export default {
  namespaced: true,
  modules: {
    active: Active,
    tariffs: Tariffs,
  },
  state: () => ({
    projects: { all: [] },
  }),
  mutations: {
    setAll: (state, data) => {
      state.projects = { ...state.projects, ...data }
    },
  },
  actions: {
    getProjects: async context => {
      const { data } = await axios.get('/support/projects')
      context.commit('setAll', data)
    },
    restoreProject: async (context, { projectId, deleteUUID }) => {
      await axios.post(`/support/projects/${projectId}/restore`, { deleteUUID })
    },
  },
}
