<template>
  <div :class="{ hidden: !ready }" class="floating-active" :style="{ top: top }" />
</template>

<script>
export default {
  name: 'FloatingActive',
  watch: {
    $route() {
      setTimeout(this.updatePosition, 200)
    },
  },
  data() {
    return {
      ready: false,
      y: 0,
    }
  },
  computed: {
    top() {
      return `${this.y}px`
    },
  },
  created() {
    setTimeout(this.updatePosition, 200)
  },
  methods: {
    updatePosition() {
      const activeItem = document.querySelector('.primary-menu .router-link-active')

      if (activeItem) {
        this.y = activeItem.offsetTop
      } else {
        this.y = -75
      }

      this.ready = true
    },
  },
}
</script>

<style lang="sass" scoped>
.floating-active
  position: absolute
  background-color: white
  width: 2px
  height: 56px
  opacity: 1
  transition: top 300ms, opacity 500ms

  &.hidden
    opacity: 0
</style>
