import axios from '@/utils/axios'

export default {
  namespaced: true,
  state: () => ({
    latest: {},
    all: {},
  }),
  mutations: {
    setAll: (state, data) => {
      state.all = { ...state.all, ...data }
    },
    setLatest: (state, data) => {
      state.latest = data
    },
  },
  actions: {
    getAll: async (context, { startDate, endDate }) => {
      const { data } = await axios.get('/stats/projects/tariffs', { params: { startDate, endDate } })
      context.commit('setAll', data)
    },
    getLatest: async context => {
      const { data } = await axios.get('/stats/projects/tariffs')
      if (data.error || data.notFound) {
        context.commit('setLatest', data)
      } else {
        context.commit('setLatest', Object.values(data)[0])
      }
    },
  },
  getters: {},
}
