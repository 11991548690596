import axios from '@/utils/axios'

export default {
  namespaced: true,
  state: () => ({
    tariffs: [],
  }),
  mutations: {
    setTariffs: (state, tariffs) => {
      state.tariffs = tariffs
    },
  },
  actions: {
    getTariffs: async context => {
      if (context.state.tariffs.length) {
        return
      }

      const { data } = await axios.get('/support/tariffs')
      context.commit('setTariffs', data)
    },
  },
  getters: {},
}
