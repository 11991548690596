export default () => {
  try {
    const cookie = Object.fromEntries(document.cookie.split(';').map(pair => pair.trim().split('=')))
    const { supportToken: token } = cookie
    const base64Url = token.split('.')[1]
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
    const uriString = atob(base64)
      .split('')
      .map(c => {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join('')
    const decodedString = decodeURIComponent(uriString)
    return JSON.parse(decodedString)
  } catch (e) {
    return null
  }
}
