import Vue from 'vue'
import './registerServiceWorker'

import './main.css'

import App from './App'
import router from './router'
import store from './store'

import '@/directives'

import spriteLoader from '@/utils/spriteLoader'

import Icon from '@/components/Icon'
import Page from '@/components/Page'

Vue.component('icon', Icon)
Vue.component('page', Page)

spriteLoader()

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
