import axios from '@/utils/axios'

export default {
  namespaced: true,
  state: () => ({
    latest: {},
    all: {},
  }),
  mutations: {
    setLatest: (state, data) => {
      state.latest = data
    },
  },
  actions: {
    getLatest: async context => {
      const { data } = await axios.get('/stats/cjm/tariffs')
      if (data.error || data.notFound) {
        context.commit('setLatest', data)
      } else {
        context.commit('setLatest', Object.values(data)[0])
      }
    },
  },
  getters: {},
}
