<template>
  <svg :width="size || 24" :height="size || 24" @click="$emit('click')">
    <use :xlink:href="href" />
  </svg>
</template>

<script>
export default {
  name: 'Icon',
  props: {
    icon: String,
    size: Number,
  },
  computed: {
    href() {
      return `#${this.icon}`
    }
  },
}
</script>
