let isInjected = false

const moveSymbolsDown = svg => {
  if (!svg) {
    return null
  }
  return Array.from(svg.querySelectorAll('symbol')).forEach(symbol => {
    symbol.parentNode.after(symbol)
  })
}

export default () => {
  if (isInjected) {
    return
  }
  isInjected = true
  const ajax = new XMLHttpRequest()
  ajax.open('GET', 'https://fm-app-uploads-dev.s3.eu-central-1.amazonaws.com/sprite.svg', true)
  ajax.send()
  ajax.onload = () => {
    const div = document.createElement('div')
    div.id = 'svgStorage'
    div.innerHTML = ajax.responseText
    moveSymbolsDown(div.querySelector('svg'))
    document.head.append(div)
  }
}
