import axios from '@/utils/axios'

import Active from './Active'
import Tariffs from './Tariffs'
import TariffsGrouped from './TariffsGrouped'

export default {
  namespaced: true,
  modules: {
    active: Active,
    tariffs: Tariffs,
    tariffsGrouped: TariffsGrouped,
  },
  state: () => ({
    users: { all: [] },
  }),
  mutations: {
    setAll: (state, data) => {
      state.users = { ...state.users, ...data }
    },
  },
  actions: {
    getUsers: async (context, { bustCache } = {}) => {
      const { data } = await axios.get('/support/users', { params: { bustCache } })
      context.commit('setAll', data)
    },
  },
}
