import Vue from 'vue'
import Vuex from 'vuex'

import CJM from './modules/CJM'
import User from './modules/User'
import Users from './modules/Users'
import Projects from './modules/Projects'
import Tariffs from './modules/Tariffs'

Vue.use(Vuex)

Vue.config.devtools = true

export default new Vuex.Store({
  modules: {
    cjm: CJM,
    user: User,
    users: Users,
    projects: Projects,
    tariffs: Tariffs,
  },
})
