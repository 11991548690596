export default [
  {
    name: 'Charts',
    icon: 'Icons/disk_space',
    permissions: ['STATS'],
    routes: [
      { name: 'ChartsUsers', icon: 'Icons/team' },
      { name: 'ChartsProjects', icon: 'Icons/page_card' },
      { name: 'ChartsTools', icon: 'Icons/userflows' },
    ],
  },
  {
    name: 'Support',
    icon: 'Icons/team',
    permissions: ['SUPPORT_BROWSE'],
    routes: [
      { name: 'SupportUsers', icon: 'Icons/team' },
      { name: 'SupportProjects', icon: 'Icons/page_card' },
    ],
  },
  {
    name: 'User',
    icon: 'Icons/user',
  },
]
